<script setup lang="ts" generic="T">
import { computed } from 'vue'
import type { HTMLAttributes } from 'vue'

import Input from '@/components/ui/input/Input.vue'
import Icon from '@/components/ui/icon/Icon.vue'

import type { InputVariants } from '.'
import type { Icons } from '@/core/utils/icons'
import { useForwardPropsEmits } from 'radix-vue'
import { cn } from '@/lib/utils'

defineOptions({
  inheritAttrs: false,
})

const props = defineProps<{
  icon?: Icons
  leading?: boolean
  trailing?: boolean
  size?: InputVariants['size']
  defaultValue?: T
  modelValue?: T
  class?: HTMLAttributes['class']
  type?: 'text' | 'number'
  disabled?: boolean
  placeholder?: string
}>()

const emits = defineEmits<{
  (e: 'update:modelValue', payload: T): void
}>()

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props

  return {
    ...delegated,
    ...(props.trailing && { class: _ + ' pr-7' }),
    ...(props.leading && { class: _ + ' pl-7' }),
  }
})

const forwarded = useForwardPropsEmits(delegatedProps, emits)
</script>

<template>
  <div :class="cn('relative z-0', props.class)">
    <div v-if="leading" class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2">
      <slot name="icon">
        <Icon :icon="icon" class="h-4 w-4 text-grey-600" aria-hidden="true" />
      </slot>
    </div>
    <Input v-bind="forwarded" />
    <div v-if="trailing" class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-4">
      <slot name="icon">
        <Icon :icon="icon" class="h-4 w-4 text-grey-600" aria-hidden="true" />
      </slot>
    </div>
  </div>
</template>
