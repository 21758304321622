<script lang="ts" setup>
import { onMounted } from 'vue'
import { storeToRefs } from 'pinia'

import { useI18n } from 'vue-i18n'
import { useTestMode } from '@/core/composables/useTestMode'

import useTransaction from '@/merchant/composables/useTransaction'

import { useQuery } from '@tanstack/vue-query'
import { apiGetCheckouts } from '@/merchant/api/checkout'
import { useAuthContext } from '@/core/composables/useAuthContext'
import { _useUserStore } from '@/merchant/stores/_user'

import { Button, Alert, Card } from '@delupay/web-core/components'
import { IconCirclePlus } from '@delupay/web-core/icons'

const emit = defineEmits(['close:modale'])

const _user = _useUserStore()

const { t } = useI18n()

const { isProductionModeEnabled, currentCheckoutNumber } = storeToRefs(_user)

const { currentCompanyId, currentPointOfSaleId, currentPointOfSale } = useAuthContext()

const { data: checkouts, isLoading } = useQuery({
  queryKey: ['checkouts', { companyId: currentCompanyId, pointOfSaleId: currentPointOfSaleId }],
  queryFn: async () => {
    const res = await apiGetCheckouts({
      company_id: currentCompanyId.value,
      point_of_sale_id: currentPointOfSaleId.value,
    })

    return res?.checkouts || []
  },
})
const offlineCheckouts = computed(() => (checkouts.value ? checkouts.value.filter((c) => !c.online) : []))

const { testMode, setTestMode } = useTestMode()

const { createTransaction, createTransactionLoading } = useTransaction()

watch(
  () => isLoading.value,
  () => {
    if (checkouts.value && checkouts.value.length > 0) {
      const checkout = checkouts.value.find((c) => c.number === currentCheckoutNumber.value)
      const isOffline = checkout && !checkout.online

      if (checkout && isOffline) {
        _user.setActiveCheckoutNumber(checkout.number)
      } else {
        _user.setActiveCheckoutNumber(offlineCheckouts.value[0].number)
      }
    }
  },
)

onMounted(() => {
  if (!isProductionModeEnabled.value) {
    setTestMode(true)
  }
})

const transactionAmount = ref()
const handleCreateTransaction = async () => {
  if (transactionAmount.value > 0) {
    await createTransaction({
      // @TODO: fix this, this should be a number...
      amount: +transactionAmount.value,
      polling: true,
    })

    transactionAmount.value = undefined
    emit('close:modale')
  }
}
</script>

<template>
  <Card class="flex flex-col gap-y-4">
    <div class="min-h-10 flex items-center">
      <p class="font-semibold">{{ t('transaction.create.title') }}</p>
    </div>
    <div class="relative z-10 flex-grow flex flex-col gap-4">
      <Select
        class="z-10"
        :loading="isLoading"
        :modelValue="currentCheckoutNumber"
        @update:modelValue="_user.setActiveCheckoutNumber($event)"
        :placeholder="t('transaction.create.checkout_counter_input.title_label')"
        :options="offlineCheckouts.map((c) => ({ value: c.number, label: c.title }))"
      />
      <IconInput
        id="amount"
        class="-z-10"
        icon="euro"
        trailing
        placeholder="0"
        v-model="transactionAmount"
        type="number"
        min="0"
        step="0.01"
        currency="€"
        :auto-focus="true"
        :disabled="offlineCheckouts && offlineCheckouts.length === 0"
        @keydown.enter="handleCreateTransaction"
      />
    </div>
    <Button
      :loading="createTransactionLoading"
      @click="handleCreateTransaction"
      size="lg"
      class="flex w-full"
      :disabled="
        transactionAmount === 0 ||
        transactionAmount === undefined ||
        transactionAmount === null ||
        transactionAmount === '' ||
        currentPointOfSale.closure_confirmed_at
      "
    >
      {{ t('transaction.create.cta') }}
      <IconCirclePlus class="h-5 w-5 absolute right-10" />
    </Button>
    <Alert v-if="testMode" variant="info" size="sm">
      <IconCircleInfo class="h-5 w-5 flex-none" />
      <p class="ml-2">{{ t('transaction.create.test_mode_description') }}</p>
    </Alert>
  </Card>
</template>
