import axios from 'axios'

import { SUPPORT_LOCALES, localeKeys } from '@/core/modules/i18n'

// @ts-ignore - @types/camelcase-object-deep does not exist
import camelcaseKeys from 'camelcase-object-deep'
import snakecaseKeys from 'snakecase-keys'
import { get } from 'lodash-es'

import bus from '../bus/eventBus'

import type { AxiosError, AxiosRequestHeaders, AxiosResponse, InternalAxiosRequestConfig } from 'axios'
import { _deleteToken } from '../composables/useAuth'

export interface ApiError {
  attribute?: string
  messages: string[]
}

export interface ApiErrorResponse {
  errors: ApiError[]
  code: number
}

export interface PaginationRequestParams {
  page: number
  perPage: number
  total: number
}

interface ErrorMessageException {
  msg: string | null
  url: string
  shouldReplace: (error?: AxiosError) => boolean
}

const DEFAULT_HEADERS = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
}

export const mapDataToSnakeCase = <T>(object: T): Snakify<T> => {
  if (!object) throw new Error('Object is empty')
  return snakecaseKeys(object, {
    deep: true,
    parsingOptions: {
      stripRegexp: /[^a-zA-Z0-9]/g,
    },
  }) as Snakify<T>
}

export const mapDataToCamelCase = <T>(object: T): Camelyse<T> => {
  return camelcaseKeys(object)
}

export const mapData = <T>(object: T, fromServer = true): T => {
  if (!object) return object
  return fromServer ? camelcaseKeys(object) : snakecaseKeys(object)
}

const instance = axios.create({
  baseURL: `${import.meta.env.VITE_BASE_URL}/api/merchant`,
  headers: {
    ...DEFAULT_HEADERS,
  },
})

const isDev = import.meta.env.DEV

const requestHandler = (request: InternalAxiosRequestConfig<any>) => {
  const testMode = window.localStorage.getItem('settings.testMode') === 'true'
  const token = window.localStorage.getItem('bearer_token')

  request.headers = {
    ...DEFAULT_HEADERS,
    ...request.headers,
    ...(isDev && token && { Authorization: `Bearer ${token}` }),
    ...(testMode && { 'x-test-mode': 'true' }),
  } as AxiosRequestHeaders

  return request
}

const responseHandler = (response: AxiosResponse) => {
  if (
    response.data &&
    response.data.message &&
    !(typeof response.data.message === 'object' && 'sender' in response.data.message)
  ) {
    bus.emit('api:message', response.data.message)
  }
  return response
}

const BlobToJson = async (blob: Blob) => {
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.onload = function () {
      resolve(JSON.parse(this.result as string).errors)
    }
    reader.readAsText(blob)
  })
}

const errorMessageExceptions: ErrorMessageException[] = [
  {
    msg: null,
    url: '/registration',
    shouldReplace: (error) => error?.response?.status === 401,
  },
  {
    msg: null,
    url: '/registration/confirmation',
    shouldReplace: () => window.location.search.includes('?token=') || window.location.search.includes('&token='),
  },
]

const checkErrorMessageExceptions = (error: AxiosError): string | undefined | null => {
  const exception = errorMessageExceptions.find((exc) => error?.response?.config?.url === exc.url)
  if (!exception || !exception.shouldReplace(error)) return undefined
  return exception.msg
}

const errorHandler = async (error: AxiosError) => {
  if (error.response && error.response.status === 401) {
    _deleteToken()
  }

  if (error.response) {
    let errors
    const exceptionMessage = checkErrorMessageExceptions(error)

    const responseData = error.response.data as Blob | { errors: ApiError[] } | null

    if (exceptionMessage) errors = [{ messages: [exceptionMessage] }]
    else if (exceptionMessage === null) errors = []
    else if (responseData instanceof Blob) errors = await BlobToJson(responseData)
    else if (responseData && 'errors' in responseData) errors = responseData.errors

    if (error && error.response && error.response.status === 401) {
      return Promise.reject(error)
    }

    bus.emit('api:errors', {
      errors,
      code: error.response.status,
    } as ApiErrorResponse)
  }

  return Promise.reject(error)
}

instance.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => errorHandler(error),
)

instance.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandler(error),
)

export const setLocale = (code: (typeof SUPPORT_LOCALES)[number]) => {
  instance.defaults.headers.common['Accept-Language'] = localeKeys[code]
}

export const getTotalFromHeaders = (res: AxiosResponse) => {
  return +get(res, "headers['x-pagination-total']", 0)
}

export interface Pagination {
  page: number
  nextPage: number | null
  prevPage: number | null
  perPage: number
  total: number
  totalPages: number
}

export const getPagination = (res: AxiosResponse): Pagination => {
  const page = res.data.page
  const total = res.data.total_items
  const perPage = res.data.per_page
  const totalPages = Math.ceil(total / perPage)

  const prevPage = page > 1 ? page - 1 : null
  const nextPage = page < totalPages ? page + 1 : null

  return {
    page,
    nextPage,
    prevPage,
    perPage,
    totalPages,
    total,
  }
}

export default instance
